import {Reducer} from 'redux'
import {createSagaAction} from '../../shared/sagas/sagas'

// Constants (Action Types)
export const constants = {
  INSERT_IMAGEM: createSagaAction('INSERT_SESSAO'),
}

/**
 * Data types
 */
export interface Imagem {
  data?: string
  filename?: string
  path?: string
  tags: string[]
}

/**
 * State type
 */
export interface ImagemState {
  readonly data: Imagem[]
  readonly obj: Imagem | [],
  readonly loading: boolean
  readonly error: boolean
}

// Reducer
export const initialState: ImagemState = {
  data: [],
  obj: [],
  loading: false,
  error: false,
}

// Action Creators
export const actions = {
  insertImagem: (imagem: Imagem) => ({
    type: constants.INSERT_IMAGEM.ACTION,
    imagem,
  }),
}

export const reducer: Reducer<ImagemState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.INSERT_IMAGEM.ACTION:
      return {...state, loading: true}
    case constants.INSERT_IMAGEM.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        obj: action.data,
      }
    case constants.INSERT_IMAGEM.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default {
  reducer,
  constants,
  actions,
  initialState,
}
