import axios from 'axios'
import {Produto} from '.'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const URL_BASE = `${API_URL}/produto`

export function getProdutos() {
  return axios.get(URL_BASE) //.then((response) => console.log(response.data.data))
}

export function findProduto(action: any) {
  return axios.get(URL_BASE + `/${action.id}`)
}

export function insertProduto(produto: Produto) {
  return axios.post(URL_BASE, produto)
}

export function updateProduto(produto: Produto) {
  return axios.put(URL_BASE + `/${produto.id}`, produto)
}

export function deleteProduto(action: any) {
  return axios.delete(URL_BASE + `/${action.id}`)
}