import React, { useEffect, useState } from "react";
import useGetFirstAndLastPages from "./Utils/useGetFirstAndLastPages";
import { Container, PaginationSpan, PaginationPages } from "./Pagination_style";
import PaginationArrows from "./PaginationArrows";
import PageNumbers from "./PageNumbers";

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    setCurrentPage: (newPage: number) => void;
    totalItems: number;
    maxItemsPerPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
    totalPages,
    currentPage,
    setCurrentPage,
    totalItems,
    maxItemsPerPage
}: PaginationProps) => {
    const { startPage, endPage } = useGetFirstAndLastPages(totalPages, currentPage);
    const [firstItemIndex, setFirstItemIndex] = useState(0);
    const [lastItemIndex, setLastItemIndex] = useState(0);

    useEffect(() => {
        let firstItemIndex = ((currentPage - 1) * maxItemsPerPage) + 1;
        let lastItemIndex = Math.min(firstItemIndex + (maxItemsPerPage - 1), totalItems);
        setFirstItemIndex(firstItemIndex);
        setLastItemIndex(lastItemIndex);
    }, [currentPage]);

    return (
        <Container>
            <PaginationSpan>
                {`Mostrando de ${firstItemIndex} até ${lastItemIndex} de ${totalPages} págin${totalPages > 1 ? 'as' : 'a'}`}
            </PaginationSpan>
            <PaginationPages>
                <PaginationArrows
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    direction={"left"}
                />
                <PageNumbers
                    startPage={startPage}
                    endPage={endPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
                <PaginationArrows
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    direction="right"
                />
            </PaginationPages>
        </Container>
    );
};

export default Pagination;
