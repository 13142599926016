export const toDateFormat = (date: string, options: {} | null = null) => {
  const newDate = new Date(date)
  const option =
    options == null
      ? { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
      : options

  return newDate.toLocaleDateString('pt-br', option)
}

export const toDateFormatAlt = (date: string, options: {} | null = null) => {
  const dateRaw = new Date(date);
  if (options) {
    return formatDate(dateRaw);
  } else {
    const dia = String(dateRaw.getDate()).padStart(2, '0');
    const mes = String(dateRaw.getMonth() + 1).padStart(2, '0');
    const hora = String(dateRaw.getHours()).padStart(2, '0');
    const ano = dateRaw.getFullYear();
    const minutos = String(dateRaw.getMinutes()).padStart(2, '0');


    return `${dia}/${mes}/${ano}, ${hora}:${minutos}`;
  }
}

function formatDate(date: any) {
  const meses = [
    'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
    'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
  ];

  const dia = String(date.getDate()).padStart(2, '0');
  const mes = String(date.getMonth() + 1).padStart(2, '0');
  const ano = date.getFullYear();
  const mesFormatado = meses[parseInt(mes, 10) - 1];

  return `${dia} de ${mesFormatado} de ${ano}`;
}

export const toCurrencyFormat = (valor: any, sifracao = true) => {
  var result = ''
  valor = parseFloat(valor)
  sifracao
    ? //com R$
    (result = valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
    : //sem R$
    (result = valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }))

  return result
}
