const sizes = {
    mobileSS: '319px',
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '410px',
    mobileVL: '500px',
    mobileUL: '650px',
    tablet: '768px',
    tabletL: '800px',
    tabletUL: '900px',
    tabletToLaptop: '991px',
    laptop: '1024px',
    desktop: '1200px',
};

export const device = {
    mobileSS: `(max-width: ${sizes.mobileSS})`,
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    mobileVL: `(min-width: ${sizes.mobileVL})`,
    mobileUL: `(min-width: ${sizes.mobileUL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    tabletL: `(min-width: ${sizes.tabletL})`,
    tabletUL: `(min-width: ${sizes.tabletL})`,
    tabletToLaptop: `(max-width: ${sizes.tabletToLaptop})`,
    laptop: `(min-width: ${sizes.laptop})`,
    desktop: `(min-width: ${sizes.desktop})`,
};