/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, FC, useContext, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {bindActionCreators, Dispatch} from 'redux'
import {RootState} from '../../../setup'
import {actions, Categoria} from '../../../store/categoria'
import CategoriaContext from '../../../contexts/CategoriaContext'
import {toDateFormat} from '../../../components/helpers'
import {FormModalCategoria} from './FormModalCategoria'

const CategoriaPage: FC = () => {
  const initialValues: Categoria = {
    nome: '',
  }

  const [obj, setObj] = useState(initialValues)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)

  const {reducer} = useContext(CategoriaContext)
  const {categoria, loading} = reducer

  function deleteCategoria(id: number) {
    if (window.confirm('Tem certeza que deseja excluir esse produto?')) reducer.deteleCategoria(id)
  }

  function showForm(item: Categoria | null) {
    setObj(item == null ? initialValues : item)
    handleShow()
  }

  return (
    <>
      <FormModalCategoria categoria={obj} show={show} setShow={setShow} />
      <div className='card card-custom gutter-b mt-6'>
        <div className='card-header border-0 py-5 pb-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bolder mb-2 text-dark'>Categorias</span>
          </h3>
          <div className='card-toolbar'>
            <a
              href='javascript:;'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                showForm(null)
              }}
            >
              Novo categoria
            </a>
          </div>
        </div>
        <div className='card-body p-5 m-5 rounded blockui' id='body-categorias'>
          <div className='row'>
            <table className='table gs-7 gy-7 gx-7'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                  <th>ID</th>
                  <th>Nome</th>
                  <th>Criado em</th>
                  <th>Atualizado em</th>
                  <th className='text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {categoria?.map((categoria: Categoria) => (
                  <tr>
                    <td>{categoria.id}</td>
                    <td className='fw-bolder fs-5'>{categoria.nome}</td>
                    <td>{toDateFormat(categoria.created_at as string)}</td>
                    <td>{toDateFormat(categoria.updated_at as string)}</td>
                    <td className='text-end'>
                      <a
                        onClick={() => {
                          showForm(categoria)
                        }}
                        href='javascript:;'
                        className='btn btn-sm btn-light-primary me-2'
                      >
                        Alterar
                      </a>
                      <a
                        href='javascript:;'
                        className='btn btn-sm btn-light-danger'
                        onClick={() => deleteCategoria(categoria.id as number)}
                      >
                        Excluir
                      </a>
                    </td>
                  </tr>
                ))}
                {!loading && categoria.length === 0 && (
                  <tr>
                    <td className='text-center text-gray-400' colSpan={6}>
                      Nenhuma categoria encontrada!
                    </td>
                  </tr>
                )}
                {loading && (
                  <tr>
                    <td className='text-center text-gray-400' colSpan={5}>
                      <span className='spinner-border align-middle me-2 mt-1'></span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

class CategoriaWrapper extends Component<PropsFromRedux> {
  componentDidMount() {
    this.props.getCategorias()
  }

  render() {
    return (
      <CategoriaContext.Provider value={{reducer: this.props}}>
        <CategoriaPage />
      </CategoriaContext.Provider>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  categoria: state.categoria.data,
  loading: state.categoria.loading,
})
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actions, dispatch)
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CategoriaWrapper)
