/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {Empresas} from './components/Empresas'
import {toAbsoluteUrl} from '../../../components/helpers'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'

function getRandomInt(min: any, max: any) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export function AuthPage() {
  const isEmpresas = useSelector<RootState>(({auth}) => auth.empresas, shallowEqual)

  const [imagem, setImagem] = useState(getRandomInt(1, 11))
  const background = {
    backgroundImage: `url('${toAbsoluteUrl('/media/background/' + imagem + '.jpg')}')`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    transition: '1.5s',
  }

  useEffect(() => {
    setTimeout(() => {
      setImagem(getRandomInt(1, 11))
    }, 10000)

    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div
          id='fundo-login'
          className='d-flex justify-content-center flex-column flex-lg-row-auto w-xl-600px positon-xl-relative'
          style={background}
        >
          <div className='d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y'>
            <div className='d-flex justify-content-center flex-row-fluid flex-column text-center p-10'>
              <a href='' className='py-10'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/BarFacil/logos/png/logo2-white-lg.png')}
                  className='h-150px'
                />
              </a>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column flex-lg-row-fluid py-10'>
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
              <>
              <Switch>
                <Route path='/auth/login' component={Login} />
                <Route path='/auth/loginEmpresa' component={Empresas} />
                <Route path='/auth/registration' component={Registration} />
                <Route path='/auth/forgot-password' component={ForgotPassword} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>

              {isEmpresas && (
                <Switch>
                  <Redirect to='/auth/loginEmpresa' />
                </Switch>
              )}
              </>
            </div>
          </div>
          <div className='d-flex flex-center flex-wrap fs-6 p-5 pb-0'>
            <div className='d-flex flex-center fw-bold fs-6'>
              <a
                href='https://www.instagram.com/agptecnologia/'
                target='_blank'
                rel='noreferrer'
                className='text-muted text-hover-primary px-2'
              >
                Sobre
              </a>
              <a
                href='#'
                className='text-muted text-hover-primary px-2'
                target='_blank'
                rel='noreferrer'
              >
                Contato
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
