/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/inicio'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Cadastros</span>
        </div>
      </div>
      <AsideMenuItem to='/categorias' icon='/media/icons/duotune/art/art002.svg' title='Categorias'/>
      <AsideMenuItem to='/produtos' icon='/media/icons/duotune/general/gen017.svg' title='Produtos'/> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Mais</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Configurações'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/coding/cod009.svg'
      >
        <AsideMenuItem to='/token' title='Token' hasBullet={true} />
        <AsideMenuItem to='/empresa-config' title='Empresa' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItem to='/crafted/pages/profile/overview' icon='/media/icons/duotune/general/gen029.svg' title='Eventos'/> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Mais Opções</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Configurações'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/coding/cod009.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
    </>
  )
}
