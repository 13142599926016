import { call, put, takeEvery } from 'redux-saga/effects'
import { constants } from './reducer'
import { getSessoesApi, insertSessao, findSessaoApi, updateSessao, getSessoesLegadoApi } from './api'
import { toast } from 'react-toastify'
import { ErrorHandler } from '../ErrorHandler'

function* getSessoesSaga(action) {
  try {
    const { data, error } = yield call(getSessoesApi, action)

    yield put({
      type: constants.GET_SESSOES.SUCCESS,
      data: { "sessoes": data.data, "metadata": data.meta },
    })
  } catch (error) {
    yield put({
      type: constants.GET_SESSOES.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* insertSessaoSaga(action) {
  try {
    yield call(insertSessao, action)
    yield put({ type: constants.INSERT_SESSAO.SUCCESS })
    toast.success('Sessão cadastrada com sucesso!')
  } catch (error) {
    yield put({
      type: constants.INSERT_SESSAO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* updateSessaoSaga(action) {
  try {
    yield call(updateSessao, action.sessao)
    yield put({ type: constants.UPDATE_SESSAO.SUCCESS })
    toast.success('Sessão atualizada com sucesso!')
  } catch (error) {
    yield put({
      type: constants.UPDATE_SESSAO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* findSessaoSaga(action) {
  try {
    const { data } = yield call(findSessaoApi, action)
    yield put({ type: constants.FIND_SESSAO.SUCCESS, data: data.data })
  } catch (error) {
    yield put({
      type: constants.FIND_SESSAO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* getSessoesLegadoSaga(action) {
  try {
    const { data } = yield call(getSessoesLegadoApi, action)
    yield put({
      type: constants.GET_SESSOES_LEGADO.SUCCESS,
      data: data.data,
    })
  } catch (error) {
    yield put({
      type: constants.GET_SESSOES_LEGADO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

export function* saga() {
  yield takeEvery(constants.GET_SESSOES.ACTION, getSessoesSaga)
  yield takeEvery(constants.INSERT_SESSAO.ACTION, insertSessaoSaga)
  yield takeEvery(constants.UPDATE_SESSAO.ACTION, updateSessaoSaga)
  yield takeEvery(constants.FIND_SESSAO.ACTION, findSessaoSaga)
  yield takeEvery(constants.GET_SESSOES_LEGADO.ACTION, getSessoesLegadoSaga)
}
