/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../setup'
import { actions, Sessao } from '../../../store/sessao'
import { bindActionCreators, Dispatch } from 'redux'
import SessaoCard from '../sessao/partials/SessaoCard'
import { KTSVG, toAbsoluteUrl } from '../../../components/helpers'
import Pagination from '../../Pagination'

class DashboardPage extends Component<PropsFromRedux> {
  state = {
    currentPage: 1
  }

  componentDidMount() {
    const { getSessoes } = this.props;
    getSessoes(this.state.currentPage);
  }

  updateCurrentPage = (currentPage: number) => {
    this.setState({ currentPage }, () => {
      const { getSessoes } = this.props;
      getSessoes(this.state.currentPage);
    });
  }

  render() {
    const { sessao, loading } = this.props;
    const sessoes = sessao.sessoes;
    const metadata = sessao.metadata;

    const buttonElement = document.getElementById(
      'kt_open_modal_create_sessao'
    ) as HTMLButtonElement

    return (
      <>
        <div className='row mb-10'>
          <div className='col-md-3 d-none'>
            <div className='row row-cols-lg-1 gy-5 g-xl-8 mb-10'>
              <div className='col'>
                <div
                  className='card shadow-sm'
                  style={{
                    borderRadius: '1rem',
                  }}
                >
                  <a
                    href='/metronic8/demo9/../demo9/account/overview.html'
                    className='btn disabled btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-center text-start w-100 p-10'
                    style={{
                      borderRadius: '1rem',
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/graphs/gra010.svg'
                      className='svg-icon-3x mb-3'
                    />

                    <span className='fs-4 fw-bolder'>Relatorios</span>
                  </a>
                </div>
              </div>
              <div className='col'>
                <div
                  className='card shadow-sm'
                  style={{
                    borderRadius: '1rem',
                  }}
                >
                  <a
                    href='/metronic8/demo9/../demo9/apps/projects/project.html'
                    className='btn disabled cursor-block btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-center text-start w-100 p-10'
                    style={{
                      borderRadius: '1rem',
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/ecommerce/ecm002.svg'
                      className='svg-icon-3x mb-3'
                    />

                    <span className='fs-4 fw-bolder'>Pedidos</span>
                  </a>
                </div>
              </div>
              <div className='col d-none d-md-block'>
                <div
                  className='card shadow-sm'
                  style={{
                    borderRadius: '1rem',
                  }}
                >
                  <a
                    href='/metronic8/demo9/../demo9/apps/projects/users.html'
                    className='btn disabled btn-flex btn-text-gray-800 btn-icon-gray-400 btn-active-color-primary bg-body flex-column justfiy-content-start align-items-center text-start w-100 p-10'
                    style={{
                      borderRadius: '1rem',
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen002.svg'
                      className='svg-icon-3x mb-3'
                    />
                    <span className='fs-4 fw-bolder'>em breve</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-md-12 ' + (!loading && sessoes?.length === 0 && 'align-self-center')}>
            <div className='row'>
              {loading && (
                <span className='mt-3 mb-6 fw-bold text-gray-400 d-flex justify-content-center'>
                  <span className='spinner-border spinner-border-sm align-middle me-2 mt-1'></span>{' '}
                  Carregando...
                </span>
              )}
              {!loading && sessoes?.length === 0 ? (
                <div className='row mt-20'>
                  <div className='col-6 text-end'>
                    <img
                      className='mw-275px'
                      src={toAbsoluteUrl('/media/illustrations/misc/1.png')}
                      alt=''
                    />
                  </div>
                  <div className='col-6 d-flex align-items-end'>
                    <div className='fw-bold mb-10'>
                      <div className='fs-7 font-gray-700'>Você ainda não tem nenhum evento!</div>
                      <div className='fs-3 fw-bolder text-primary mb-6'>
                        Vamos começar a vender ?
                      </div>
                      <a
                        href='#'
                        onClick={() => {
                          buttonElement.click()
                        }}
                        className='btn btn-sm btn-primary px-8'
                      >
                        Cadastrar um evento
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='col-md-4 col-xl-3 mb-6'>
                  <a
                    href='#'
                    onClick={() => {
                      buttonElement.click()
                    }}
                  >
                    <div
                      className='card card-xl-stretch border border-hover border-2 border-primary shadow-sm bg-white h-100 d-flex flex-column justify-content-center align-items-center h-150px h-md-225px'
                      style={{
                        borderRadius: '1rem',
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr075.svg'
                        className='svg-icon-2x svg-icon-primary my-8'
                      />
                      <div className='fs-4 fw-bold'>Adicionar novo</div>
                      <div className='fs-2 fw-boldest mt-n1'>EVENTO</div>
                    </div>
                  </a>
                </div>
              )}
              {sessoes?.map((sessao: Sessao) => (
                <div className='col-md-4 col-xl-3 mb-6'>
                  <Link to={`/sessao/${sessao.id}/edit`}>
                    <SessaoCard sessao={sessao} />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
        {
          !loading && (metadata?.last_page > 1) && <Pagination
            totalPages={metadata?.last_page}
            currentPage={metadata?.current_page}
            setCurrentPage={this.updateCurrentPage}
            totalItems={metadata?.total}
            maxItemsPerPage={metadata?.per_page}
          />
        }
      </>
    )
  }
}

//Todo - Pq esse FC ele fica em loop infinito?
// const DashboardPage: FC<PropsFromRedux> = (props) => {
//   console.log(props)
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const teste = async () => {
//       try {
//         dispatch(props.getSessoes());
//       } catch (error) {
//         console.error(error)
//       }
//     }
//     teste();
//   })

//   return (
//     <>
//       <div className='row gy-5 g-xl-8 mb-10'>
//         <div className='col-xl-4'>
//           <a href='#'>
//             <div className='card card-xl-stretch h-100'>
//               <div className='card-body'>
//                 <div className='text-gray-800 fw-bolder fs-4'>Relátorios</div>
//                 <div className='text-muted fs-8 mt-3'>Suas vendas de forma detalhada</div>
//               </div>
//             </div>
//           </a>
//         </div>
//         <div className='col-xl-4'>
//           <a href='#'>
//             <div className='card card-xl-stretch h-100'>
//               <div className='card-body'>
//                 <div className='text-gray-800 fw-bolder fs-4'>Pedidos</div>
//                 <div className='text-muted fs-8 mt-3'>Os pedidos efetuados por seus clientes</div>
//               </div>
//             </div>
//           </a>
//         </div>
//         <div className='col-xl-4'>
//           <a href='#'>
//             <div className='card card-xl-stretch h-100'>
//               <div className='card-body'>
//                 <div className='text-gray-800 fw-bolder fs-4'>Titulo</div>
//                 <div className='text-muted fs-8 mt-3'>Breve descrição</div>
//               </div>
//             </div>
//           </a>
//         </div>
//       </div>

//       <div className='row'>
//         <div className='d-flex flex-wrap flex-stack mb-6 pt-10'>
//           <h3 className='fw-bolder my-2'>
//             Sessões <span className='fs-6 text-gray-400 fw-bold ms-1'>ativas</span>
//           </h3>
//           <div className='d-flex flex-wrap my-2'>
//             {/* <a
//               href='#'
//               className='btn btn-primary btn-sm'
//               data-bs-toggle='modal'
//               data-bs-target='#modal_new_sessao'
//             >
//               Novo evento
//             </a> */}
//           </div>
//         </div>
//         <div className='col-xl-4 col-xxl-3 mb-6'>
//           <Link to={'/sessao/1/edit'}>
//             <div className='card card-xl-stretch h-100 border border-hover border-2 border-primary'>
//               <div className='card-body p-6'>
//                 {/* <img src="demo1/media/stock/600x400/img-39.jpg" onError="this.src='http://local.admin.barfacil.com.br/demo1/media/stock/600x400/img-39.jpg';" className="w-100 rounded mb-3" alt=""> */}
//                 <img src='/media/stock/600x400/img-39.jpg' className='w-100 rounded mb-3' alt='' />
//                 <div className='text-gray-800 fw-bolder fs-5'>TOGETHER WE RISE</div>
//                 <div className='text-muted fs-8'>27/11/2021</div>
//               </div>
//             </div>
//           </Link>
//         </div>
//       </div>
//     </>
//   )
// }

const mapStateToProps = (state: RootState) => ({
  sessao: state.sessao.data,
  loading: state.sessao.loading,
})
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(actions, dispatch)
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DashboardPage)
//export {DashboardPage}
