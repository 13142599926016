import {Reducer} from 'redux'
import {createSagaAction} from '../../shared/sagas/sagas'
import {Produto} from '../produto'

// Constants (Action Types)
export const constants = {
  GET_SESSAO_PRODUTOS: createSagaAction('GET_SESSAO_PRODUTOS'),
  SET_SESSAO_PRODUTOS: createSagaAction('SET_SESSAO_PRODUTOS'),
  GET_SUGESTOES_PRODUTOS: createSagaAction('GET_SUGESTOES_PRODUTOS'),
  INSERT_SESSAO_PRODUTO: createSagaAction('INSERT_SESSAO_PRODUTO'),
  UPDATE_SESSAO_PRODUTO: createSagaAction('UPDATE_SESSAO_PRODUTO'),
  DELETE_SESSAO_PRODUTO: createSagaAction('DELETE_SESSAO_PRODUTO'),
}

/**
 * Data types
 */
export interface SessaoProduto {
  id?: number
  pub_sessao_id?: number
  pub_produto_id?: number
  posicao?: number
  valor: number

  produto?: Produto

  created_at?: string
  updated_at?: string
  deleted_at?: string
}

/**
 * State type
 */
export interface SessaoProdutoState {
  readonly data: SessaoProduto[]
  readonly sugestoes: Produto[]
  readonly obj: SessaoProduto | []
  readonly loading: boolean
  readonly error: boolean
}

// Reducer
export const initialState: SessaoProdutoState = {
  data: [],
  sugestoes: [],
  obj: [],
  loading: false,
  error: false,
}

// Action Creators
export const actions = {
  getSessaoProdutos: (sessao_id: number) => ({
    type: constants.GET_SESSAO_PRODUTOS.ACTION,
    sessao_id,
  }),
  setSessaoProdutos: () => ({
    type: constants.SET_SESSAO_PRODUTOS.ACTION,
  }),
  insertSessaoProduto: (sessaoProduto: SessaoProduto) => ({
    type: constants.INSERT_SESSAO_PRODUTO.ACTION,
    sessaoProduto,
  }),
  updateSessaoProduto: (sessaoProduto: SessaoProduto) => ({
    type: constants.UPDATE_SESSAO_PRODUTO.ACTION,
    sessaoProduto,
  }),
  getSugestoes: (sessao_id: number) => ({
    type: constants.GET_SUGESTOES_PRODUTOS.ACTION,
    sessao_id,
  }),
  deleteSessaoProduto: (id: number, sessao_id: number) => ({
    type: constants.DELETE_SESSAO_PRODUTO.ACTION,
    id,
    sessao_id,
  }),
}

export const reducer: Reducer<SessaoProdutoState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_SESSAO_PRODUTOS.ACTION:
      return {...state, loading: true}
    case constants.GET_SESSAO_PRODUTOS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      }
    case constants.GET_SESSAO_PRODUTOS.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      }
    case constants.INSERT_SESSAO_PRODUTO.ACTION:
      return {...state, loading: true}
    case constants.INSERT_SESSAO_PRODUTO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.INSERT_SESSAO_PRODUTO.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.UPDATE_SESSAO_PRODUTO.ACTION:
      return {...state, loading: true}
    case constants.UPDATE_SESSAO_PRODUTO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.UPDATE_SESSAO_PRODUTO.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.GET_SUGESTOES_PRODUTOS.ACTION:
      return {...state, loading: true}
    case constants.GET_SUGESTOES_PRODUTOS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        sugestoes: action.data,
      }
    case constants.GET_SUGESTOES_PRODUTOS.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        sugestoes: [],
      }
    case constants.DELETE_SESSAO_PRODUTO.ACTION:
      return {...state, loading: true}
    case constants.DELETE_SESSAO_PRODUTO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.DELETE_SESSAO_PRODUTO.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default {
  reducer,
  constants,
  actions,
  initialState,
}
