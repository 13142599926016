import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const SelectedRowsCtx = createContext<any>(undefined);

const SelectedRowsCtxWrapper = ({ children }: { children: any }) => {
    const location = useLocation();
    const [selectedRows, setSelectedRows] = useState<any>([]);

    const resetSelectedRowsCtx = () => {
        setSelectedRows([]);
    };

    useEffect(() => {
        const isReembolsosUrl = location.pathname.match(/^\/sessao\/\d+\/reembolsos$/);

        if (!isReembolsosUrl) {
            resetSelectedRowsCtx();
        }
    }, [location.pathname]);

    return (
        <SelectedRowsCtx.Provider value={{ selectedRows, setSelectedRows }}>
            {children}
        </SelectedRowsCtx.Provider>
    );
};

export default SelectedRowsCtxWrapper;
