import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const InsightsCtx = createContext<any>(undefined);

const InsightsCtxWrapper = ({ children }: { children: any }) => {
    const location = useLocation();
    const [tagReembolsoValuesSum, setTagReembolsoValuesSum] = useState<number>();
    const [tagReembolsoValuesSumBySituation, setTagReembolsoValuesSumBySituation] = useState<[]>([]);
    const [tagReembolsoAmount, setTagReembolsoAmount] = useState<number>();
    const [tagReembolsoAmountBySituation, setTagReembolsoAmountBySituation] = useState<[]>([]);
    const [tagReembolsoTaxaSum, setTagReembolsoTaxaSum] = useState(0);

    const resetInsightsCtx = () => {
        setTagReembolsoValuesSum(0);
        setTagReembolsoValuesSumBySituation([]);
        setTagReembolsoAmount(0);
        setTagReembolsoAmountBySituation([]);
        setTagReembolsoTaxaSum(0);
    }
    
    useEffect(() => {
        const isReembolsosUrl = location.pathname.match(/^\/sessao\/\d+\/reembolsos$/);

        if (!isReembolsosUrl) {
            resetInsightsCtx();
        }
    }, [location.pathname]);

    return (
        <InsightsCtx.Provider
            value={{
                tagReembolsoValuesSum, setTagReembolsoValuesSum,
                tagReembolsoValuesSumBySituation, setTagReembolsoValuesSumBySituation,
                tagReembolsoAmount, setTagReembolsoAmount,
                tagReembolsoAmountBySituation, setTagReembolsoAmountBySituation,
                tagReembolsoTaxaSum, setTagReembolsoTaxaSum,
            }}
        >
            {children}
        </InsightsCtx.Provider>
    );
};

export default InsightsCtxWrapper;
