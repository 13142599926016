import axios from 'axios'
import {Sessao} from '../sessao'
import {Filters} from './reducer'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const URL_BASE = `${API_URL}/tag-reembolso`

export function getReembolsos(sessao: Sessao, filtros: Filters | undefined) {
  const URL =
    filtros !== undefined
      ? `${URL_BASE}/${sessao.id}?perPage=${filtros.header.perPage}&${filtros.header.page}`
      : `${URL_BASE}/${sessao.id}`
      
  return axios.post(URL, filtros?.body)
}