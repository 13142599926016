import axios from 'axios'
import { SessaoProduto } from '.'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const URL_BASE = `${API_URL}/sessao-produto`

export function getSessaoProdutos(action: any) {
  return axios.get(URL_BASE + `/${action.sessao_id}`)
}

export function insertSessaoProduto(sessaoProduto: SessaoProduto) {
  return axios.post(URL_BASE, sessaoProduto)
}

export function updateSessaoProduto(sessaoProduto: SessaoProduto) {
  return axios.put(URL_BASE + `/${sessaoProduto.id}`, sessaoProduto)
}

export function getSugestoes(action: any) {
  return axios.get(URL_BASE+ `/${action.sessao_id}/sugestao`)
}

export function deleteSessaoProduto(action: any) {
  return axios.delete(URL_BASE + `/${action.id}`)
}