import axios from 'axios'
import {Sessao} from '.'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const URL_BASE = `${API_URL}/sessao`

export function getSessoesApi(action: any) {
  return axios.get(`${URL_BASE}?page=${action.page}`) //.then((response) => console.log(response.data.data))
}

export function findSessaoApi(action: any) {
  return axios.get(URL_BASE + `/${action.id}`)
}

export function insertSessao(sessao: Sessao) {
  return axios.post(URL_BASE, sessao)
}

export function updateSessao(sessao: Sessao) {
  return axios.put(URL_BASE + `/${sessao.id}`, sessao)
}

export function getSessoesLegadoApi() {
  return axios.get(URL_BASE+'-list-bf')
}