import React from "react";
import { PaginationPageNumber } from "./Pagination_style";

interface PageNumbersProps {
    startPage: number;
    endPage: number;
    currentPage: number;
    setCurrentPage: (newPage: number) => void;
}

const PageNumbers: React.FC<PageNumbersProps> = ({ startPage, endPage, currentPage, setCurrentPage }: PageNumbersProps) => {
    const pageNumbers: number[] = [];

    if (startPage && endPage) {
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
    }

    return (
        <>
            {pageNumbers.map((pageNumber) => (
                <PaginationPageNumber
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={pageNumber === currentPage ? "currentPage" : ""}
                >
                    {pageNumber}
                </PaginationPageNumber>
            ))}
        </>
    );
};

export default PageNumbers;
