import { createContext, ReactNode, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const FiltersCtx = createContext<any>(undefined);

const FiltersCtxWrapper = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const [filters, setFilters] = useState<Array<number>>([0, 1]);

    const resetFiltersCtx = () => {
        setFilters([0, 1]);
    };

    useEffect(() => {
        const isReembolsosUrl = location.pathname.match(/^\/sessao\/\d+\/reembolsos$/);

        if (!isReembolsosUrl) {
            resetFiltersCtx();
        }
    }, [location.pathname]);

    return (
        <FiltersCtx.Provider value={{ filters, setFilters }}>
            {children}
        </FiltersCtx.Provider>
    );
};

export default FiltersCtxWrapper;
