import { Reducer } from 'redux'
import { createSagaAction } from '../../shared/sagas/sagas'
import { Sessao } from '../sessao'

// Constants (Action Types)
export const constants = {
  GET_REEMBOLSOS: createSagaAction('GET_REEMBOLSOS'),
}

/**
 * Data types
 */
export interface Reembolso {
  id?: number

  adm_pessoa_id?: number
  pub_tag_id?: number
  pub_sessao_id?: number
  adm_empresa_id?: number

  nome: string
  hexadecimal: string

  situacao?: number
  valor_bruto?: number

  created_at?: string
  updated_at?: string
}

export interface Paginate {
  current_page?: number
  first_page_url?: string
  from?: number
  last_page?: number
  last_page_url?: string
  next_page_url?: string
  path?: string
  per_page?: number
  prev_page_url?: string
  to?: number
  total?: number
  links?: Link[]
}

export interface Link {
  url?: string
  label?: string
  active?: boolean
}

export interface Filters {
  header: {
    perPage?: string
    page?: string
  }
  body: {
    search?: string
    situacao?: number | undefined
  }
}

/**
 * State type
 */
export interface ReembolsoState {
  readonly list: Reembolso[]
  readonly paginante: Paginate | null
  readonly loading: boolean
  readonly error: boolean
}

// Reducer
export const initialState: ReembolsoState = {
  list: [],
  paginante: null,
  loading: false,
  error: false,
}

// Action Creators
export const actions = {
  getReembolsos: (sessao: Sessao, filtros: Filters | undefined = undefined) => ({
    type: constants.GET_REEMBOLSOS.ACTION,
    sessao,
    filtros,
  }),
}

export const reducer: Reducer<ReembolsoState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_REEMBOLSOS.ACTION:
      return { ...state, loading: true }
    case constants.GET_REEMBOLSOS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        list: action.data.data,
        paginante: {
          current_page: action.data.current_page,
          first_page_url: action.data.first_page_url,
          from: action.data.from,
          last_page_url: action.data.last_page_url,
          next_page_url: action.data.next_page_url,
          path: action.data.path,
          per_page: action.data.per_page,
          prev_page_url: action.data.prev_page_url,
          to: action.data.to,
          total: action.data.total,
          links: action.data.links,
        },
      }
    case constants.GET_REEMBOLSOS.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
        paginante: null,
      }
    default:
      return state
  }
}

export default {
  reducer,
  constants,
  actions,
  initialState,
}
