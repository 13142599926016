/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {Formik, Form, Field, ErrorMessage, FormikHelpers} from 'formik'
import * as Yup from 'yup'
import {insertSessao, Sessao} from '../../../../store/sessao'
import {useHistory} from 'react-router'
import { handleErrorsByKey } from '../../../../store/ErrorHandler'

const initialValues: Sessao = {
  nome: '',
  inicio: '',
  termino: '',
}

const createSessaoSchema = Yup.object().shape({
  nome: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('O nome do evento é um campo obrigatório'),
  inicio: Yup.date()
    .max(Yup.ref('termino'), 'A data de inicio precisa ser menor ou igual que a de termino')
    .required('A data de inicio é obrigatória'),
  termino: Yup.date()
    .min(Yup.ref('inicio'), 'A data de termino precisa ser maior ou igual que a de inicio')
    .required('A data de termino é obrigatória'),
})

const CreateSessao: FC = () => {
  const [loading] = useState(false)
  const [currentSchema] = useState(createSessaoSchema)
  const [initValues] = useState<Sessao>(initialValues)
  const history = useHistory()
  const btnClose: HTMLElement = document.querySelector("#close_modal_create_sessao") as HTMLElement;

  const submitStep = (values: Sessao, { setErrors }: FormikHelpers<Sessao>) => {
    insertSessao(values).then(({data: {data: sessao}}) => {
      btnClose.click();
      history.push(`/sessao/${sessao.id}/edit`)
    }).catch((err) => {
      setErrors(handleErrorsByKey(err, Object.keys(values) as (keyof Sessao)[]))
    })
  }

  return (
    <div className='modal fade' id='kt_modal_create_sessao' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered mw-700px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0'>
            <h2></h2>

            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id="close_modal_create_sessao">
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body py-lg-10 px-lg-10'>
            <div className='mb-15 text-center'>
              <h2 className='mb-2'>
                Cadastro de um novo <mark>evento</mark>
              </h2>
              <div className='text-muted fw-bold fs-6'>
                Crie uma nova sessão para venda de produtos antecipada!
              </div>
            </div>
            <div
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_create_app_stepper'
            >
              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <Formik
                  validationSchema={currentSchema}
                  initialValues={initValues}
                  onSubmit={submitStep}
                >
                  {() => (
                    <Form className='form' noValidate id='kt_modal_create_app_form'>
                      <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Titulo do evento</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-toggle="tooltip"
                            data-placement="right"
                            title="Nome do seu evento"
                          />
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='nome'
                          placeholder='Insira um título para o evento'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='nome' />
                        </div>
                      </div>

                      <div className='row mb-10'>
                        <div className='col-md-6'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Data de inicio</span>
                          </label>
                          <Field
                            type='datetime-local'
                            className='form-control form-control-lg form-control-solid'
                            name='inicio'
                            placeholder='Insira um título para o evento'
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='inicio' />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Data de termino</span>
                          </label>
                          <Field
                            type='datetime-local'
                            className='form-control form-control-lg form-control-solid'
                            name='termino'
                            placeholder='Insira um título para o evento'
                          />
                          <div className='text-danger mt-2'>
                            <ErrorMessage name='termino' />
                          </div>
                        </div>
                      </div>

                      <div className='d-flex justify-content-end pt-10'>
                        <button type='submit' className='btn btn-lg btn-primary'>
                          {!loading && (
                            <span className='indicator-label'>
                              Salvar
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr084.svg'
                                className='svg-icon-3 ms-2 me-0'
                              />
                            </span>
                          )}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Carregando...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CreateSessao}
