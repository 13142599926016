import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScreenSizeCtx = createContext<any>(undefined);

const ScreenSizeCtxWrapper = ({ children }: { children: any }) => {
    const location = useLocation();
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);

    const resetScreenSizeCtx = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const isReembolsosUrl = location.pathname.match(/^\/sessao\/\d+\/reembolsos$/);

        if (!isReembolsosUrl) {
            resetScreenSizeCtx();
        }
    }, [location.pathname]);

    return (
        <ScreenSizeCtx.Provider value={
            {
                screenWidth, setScreenWidth,
                screenHeight, setScreenHeight
            }}>
            {children}
        </ScreenSizeCtx.Provider>
    );
};

export default ScreenSizeCtxWrapper;
