import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const SearchCtx = createContext<any>(undefined);

const SearchCtxWrapper = ({ children }: { children: any }) => {
  const location = useLocation();
  const [search, setSearch] = useState<string>("");

  const resetSearchCtx = () => {
    setSearch("");
  };

  useEffect(() => {
    const isReembolsosUrl = location.pathname.match(/^\/sessao\/\d+\/reembolsos$/);

    if (!isReembolsosUrl) {
      resetSearchCtx();
    }
  }, [location.pathname]);

  return (
    <SearchCtx.Provider value={{ search, setSearch }}>
      {children}
    </SearchCtx.Provider>
  );
};

export default SearchCtxWrapper;
