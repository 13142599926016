import {Reducer} from 'redux'
import {createSagaAction} from '../../shared/sagas/sagas'

// Constants (Action Types)
export const constants = {
  GET_CATEGORIAS: createSagaAction('GET_CATEGORIAS'),
  FIND_CATEGORIA: createSagaAction('FIND_CATEGORIA'),
  INSERT_CATEGORIA: createSagaAction('INSERT_CATEGORIA'),
  UPDATE_CATEGORIA: createSagaAction('UPDATE_CATEGORIA'),
  DELETE_CATEGORIA: createSagaAction('DELETE_CATEGORIA'),
}

/**
 * Data types
 */
export interface Categoria {
  id?: number
  adm_empresa_id?: number
  nome: string

  created_at?: string
  updated_at?: string
  deleted_at?: string
}

/**
 * State type
 */
export interface CategoriaState {
  readonly data: Categoria[]
  readonly obj: Categoria | []
  readonly loading: boolean
  readonly error: boolean
}

// Reducer
export const initialState: CategoriaState = {
  data: [],
  obj: [],
  loading: false,
  error: false,
}

// Action Creators
export const actions = {
  getCategorias: () => ({type: constants.GET_CATEGORIAS.ACTION}),
  findCategoria: (id: number) => ({type: constants.FIND_CATEGORIA.ACTION, id}),
  insertCategoria: (categoria: Categoria) => ({
    type: constants.INSERT_CATEGORIA.ACTION,
    categoria,
  }),
  updateCategoria: (categoria: Categoria) => ({
    type: constants.UPDATE_CATEGORIA.ACTION,
    categoria,
  }),
  deleteCategoria: (id: number) => ({type: constants.DELETE_CATEGORIA.ACTION, id}),
}

export const reducer: Reducer<CategoriaState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_CATEGORIAS.ACTION:
      return {...state, loading: true}
    case constants.GET_CATEGORIAS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      }
    case constants.GET_CATEGORIAS.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      }
    case constants.INSERT_CATEGORIA.ACTION:
      return {...state, loading: true}
    case constants.INSERT_CATEGORIA.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.INSERT_CATEGORIA.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.UPDATE_CATEGORIA.ACTION:
      return {...state, loading: true}
    case constants.UPDATE_CATEGORIA.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.UPDATE_CATEGORIA.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.FIND_CATEGORIA.ACTION:
      return {...state, loading: true, obj: []}
    case constants.FIND_CATEGORIA.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        obj: action.data,
      }
    case constants.FIND_CATEGORIA.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.DELETE_CATEGORIA.ACTION:
      return {...state, loading: true}
    case constants.DELETE_CATEGORIA.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.DELETE_CATEGORIA.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default {
  reducer,
  constants,
  actions,
  initialState,
}
