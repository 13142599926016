import { MenuItem } from './MenuItem'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'

interface RouteParams {
  sessao: string;
}

export function MenuInner() {
  const intl = useIntl()
  const match = useRouteMatch<RouteParams>('/sessao/:sessao/reembolsos');
  const sessao = match?.params.sessao;

  return (
    <>
      {
        match
          ? <MenuItem title={'Voltar'} to={`/sessao/${sessao}/edit`} />
          : <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/inicio' />
      }
      {/* <MenuInnerWithSub
        title='Cadastros'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/categorias' title='Categorias' hasBullet={true} />
        <MenuItem to='/produtos' title='Produtos' hasBullet={true} />
      </MenuInnerWithSub> */}
    </>
  )
}
