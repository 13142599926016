import styled from "styled-components";
import leftArrow from "./assets/leftArrow.svg";
import rightArrow from "./assets/rightArrow.svg";
import { device } from "../pages/ReembolsosDashboard/Utils/media-queries";

export const Container = styled.div`
    width: 100%;
    height: 16px;
    display: flex;
    margin-top: 18px;
    align-items: center;
    justify-content: space-between;

    div {
        cursor: pointer;
    }

    @media ${device.tabletToLaptop} {
        row-gap: 10px;
        margin-top: 30px;
        margin-bottom: 40px;
        flex-direction: column;
    }
`;

export const PaginationSpan = styled.span`
    color: #5E6278;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
`;

export const PaginationPages = styled.div`
    display: flex;

    @media ${device.tabletToLaptop} {
        gap: 25px;
    }
`;

const PaginationPageArrow = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 6px;
    align-items: center;
    background: #FFFFFF;
    justify-content: center;

    img {
        width: 20px;
        height: 10px;
    }
`;

export const LeftArrow = ({ visible, onClick }: { visible: boolean, onClick: any }) => {
    return (
        <PaginationPageArrow onClick={() => onClick()} style={{ display: visible ? 'flex' : 'none' }}>
            <img src={leftArrow} alt="" />
        </PaginationPageArrow>
    );
};

export const RightArrow = ({ visible, onClick }: { visible: boolean, onClick: any }) => {
    return (
        <PaginationPageArrow onClick={() => onClick()} style={{ display: visible ? 'flex' : 'none' }}>
            <img src={rightArrow} alt="" />
        </PaginationPageArrow>
    );
};

export const PaginationPageNumber = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    font-size: 14px;
    color: #7E8299;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    border-radius: 6px;
    align-items: center;
    justify-content: center;

    &&.currentPage {
        color: #FFFFFF;
        background: rgba(0, 163, 255, 0.7);
    }

    @media ${device.tabletToLaptop} {
        margin-bottom: 30px;
    }
`;