/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
}

const FeedsWidget1: React.FC<Props> = ({className}) => {
  return (
    <></>
  )
}

export {FeedsWidget1}
