import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ReembolsosCtx = createContext<any>(undefined);

const ReembolsosCtxWrapper = ({ children }: { children: any }) => {
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsList, setItemsList] = useState<any>(undefined);
    const [maxItemsPerPage, setMaxItemsPerPage] = useState(20);
    const [totalPages, setTotalPages] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [thereAreRequests, setThereAreRequests] = useState<boolean>(true);
    const [totalItems, setTotalItems] = useState<number | undefined>(undefined);

    const resetReembolsosCtx = () => {
        setCurrentPage(1);
        setMaxItemsPerPage(20);
        setItemsList(undefined);
        setTotalPages(undefined);
        setTotalItems(undefined);
        setThereAreRequests(false);
        setIsLoading(true);
    };

    useEffect(() => {
        const isReembolsosUrl = location.pathname.match(/^\/sessao\/\d+\/reembolsos$/);

        if (!isReembolsosUrl) {
            resetReembolsosCtx();
        }
    }, [location.pathname]);

    return (
        <ReembolsosCtx.Provider
            value={{
                itemsList, setItemsList,
                totalItems, setTotalItems,
                totalPages, setTotalPages,
                currentPage, setCurrentPage,
                maxItemsPerPage, setMaxItemsPerPage,
                thereAreRequests, setThereAreRequests,
                isLoading, setIsLoading
            }}
        >
            {children}
        </ReembolsosCtx.Provider>
    );
};

export default ReembolsosCtxWrapper;
