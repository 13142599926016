import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const PendingRowsCtx = createContext<any>(undefined);

const PendingRowsCtxWrapper = ({ children }: { children: any }) => {
    const location = useLocation();
    const [pendingRows, setPendingRows] = useState<number>(0);

    const resetPendingRowsCtx = () => {
        setPendingRows(0);
    };

    useEffect(() => {
        const isReembolsosUrl = location.pathname.match(/^\/sessao\/\d+\/reembolsos$/);

        if (!isReembolsosUrl) {
            resetPendingRowsCtx();
        }
    }, [location.pathname]);

    return (
        <PendingRowsCtx.Provider value={{ pendingRows, setPendingRows }}>
            {children}
        </PendingRowsCtx.Provider>
    );
};

export default PendingRowsCtxWrapper;
