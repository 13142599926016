import {Reducer} from 'redux'
import {createSagaAction} from '../../shared/sagas/sagas'
import {Categoria} from '../categoria'

// Constants (Action Types)
export const constants = {
  GET_PRODUTOS: createSagaAction('GET_PRODUTOS'),
  FIND_PRODUTO: createSagaAction('FIND_PRODUTO'),
  INSERT_PRODUTO: createSagaAction('INSERT_PRODUTO'),
  UPDATE_PRODUTO: createSagaAction('UPDATE_PRODUTO'),
  DELETE_PRODUTO: createSagaAction('DELETE_PRODUTO'),
}

/**
 * Data types
 */
export interface Produto {
  id?: number
  adm_empresa_id?: number
  pub_categoria_id?: number
  nome: string
  valor: number
  imagem?: string

  categoria?: Categoria

  created_at?: string
  updated_at?: string
  deleted_at?: string
}

/**
 * State type
 */
export interface ProdutoState {
  readonly data: Produto[]
  readonly obj: Produto | []
  readonly loading: boolean
  readonly error: boolean
}

// Reducer
export const initialState: ProdutoState = {
  data: [],
  obj: [],
  loading: false,
  error: false,
}

// Action Creators
export const actions = {
  getProdutos: () => ({type: constants.GET_PRODUTOS.ACTION}),
  findProduto: (id: string) => ({type: constants.FIND_PRODUTO.ACTION, id}),
  insertProduto: (produto: Produto) => ({
    type: constants.INSERT_PRODUTO.ACTION,
    produto,
  }),
  updateProduto: (produto: Produto) => ({
    type: constants.UPDATE_PRODUTO.ACTION,
    produto,
  }),
  deleteProduto: (id: number) => ({type: constants.DELETE_PRODUTO.ACTION, id}),
}

export const reducer: Reducer<ProdutoState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_PRODUTOS.ACTION:
      return {...state, loading: true}
    case constants.GET_PRODUTOS.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      }
    case constants.GET_PRODUTOS.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
      }
    case constants.INSERT_PRODUTO.ACTION:
      return {...state, loading: true}
    case constants.INSERT_PRODUTO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.INSERT_PRODUTO.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.UPDATE_PRODUTO.ACTION:
      return {...state, loading: true}
    case constants.UPDATE_PRODUTO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.UPDATE_PRODUTO.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.FIND_PRODUTO.ACTION:
      return {...state, loading: true, obj: []}
    case constants.FIND_PRODUTO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        obj: action.data,
      }
    case constants.FIND_PRODUTO.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case constants.DELETE_PRODUTO.ACTION:
      return {...state, loading: true}
    case constants.DELETE_PRODUTO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.DELETE_PRODUTO.FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

export default {
  reducer,
  constants,
  actions,
  initialState,
}
