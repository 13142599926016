/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import { register } from '../redux/AuthApi'
import { Link } from 'react-router-dom'

const initialValues = {
  nome: '',
  cpf: '',
  email: '',
  password: '',
}

const registrationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Mínino de 3 caracteres')
    .max(50, 'Máximo de 50 caracteres')
    .required('O nome é necessário'),
  cpf: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('O CPF é necessário'),
  email: Yup.string()
    .email('Formato de email errado')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('O Email é necessário'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('A senha é necessária'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const formatCPF = (value: string) => {
    const numericValue = value.replace(/\D/g, '');
    const formattedValue = numericValue.slice(0, 11);
      
    return formattedValue
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        register(values.nome, values.email, values.cpf, values.password)
          .then(({ data }) => {
            setLoading(false)
            dispatch(auth.actions.login(data.auth.token.access_token))
          })
          .catch((error) => {
            setLoading(false);
            setSubmitting(false);
            if (error.response) {
              const responseData = error.response.data;
              if (responseData.errors) {
                const errorMessages = Object.keys(responseData.errors).map((fieldName) => {
                  return `* ${responseData.errors[fieldName][0]}`;
                });
                setStatus(errorMessages.join('\n'));
              } else if (responseData.message) {
                setStatus(responseData.message);
              } else {
                setStatus('Erro desconhecido ao registrar. Tente novamente mais tarde.');
              }
            } else {
              setStatus('Erro de conexão. Tente novamente mais tarde.');
            }
          });
      }, 1000)
    },
  })

  const handleCPFChange = (event: { target: { value: string } }) => {
    const formattedValue = formatCPF(event.target.value);
    formik.setFieldValue('cpf', formattedValue);
  };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Registrar</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>Informe seus dados para acessar</div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {formik.status.split('\n').map((errorMessage: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, index: React.Key | null | undefined) => (
              <div key={index}>{errorMessage}</div>
            ))}
          </div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-12'>
          <label className='class="form-label fw-bolder text-dark fs-6'>Nome completo</label>
          <input
            placeholder='Nome completo'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('nome')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.nome && formik.errors.nome,
              },
              {
                'is-valid': formik.touched.nome && !formik.errors.nome,
              }
            )}
          />
          {formik.touched.nome && formik.errors.nome && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.nome}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>CPF</label>
        <input
          placeholder='Seu cpf'
          type='text'
          {...formik.getFieldProps('cpf')}
          onChange={handleCPFChange}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.cpf && formik.errors.cpf },
            {
              'is-valid': formik.touched.cpf && !formik.errors.cpf,
            }
          )}
        />
        {formik.touched.cpf && formik.errors.cpf && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.cpf}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>E-mail</label>
        <input
          placeholder='Seu e-mail'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Senha'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center d-flex justify-content-between'>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary'
          >
            Voltar
          </button>
        </Link>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary'
          disabled={formik.isSubmitting}
        >
          {!loading && <span className='indicator-label'>Cadastrar</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Carregando...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
