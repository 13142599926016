import {call, put, takeEvery} from 'redux-saga/effects'
import {constants, actions} from './reducer'
import {getProdutos, insertProduto, findProduto, updateProduto, deleteProduto} from './api'
import {toast} from 'react-toastify'
import { ErrorHandler } from '../ErrorHandler'

function* getSessoesSaga(action) {
  try {
    const {data} = yield call(getProdutos, action)
    yield put({
      type: constants.GET_PRODUTOS.SUCCESS,
      data: data.data,
    })
  } catch (error) {
    yield put({
      type: constants.GET_PRODUTOS.FAILED,
      error: error,
    })
  }
}

function* insertProdutoSaga(action) {
  try {
    yield call(insertProduto, action.produto)
    yield put({type: constants.INSERT_PRODUTO.SUCCESS})
    yield put(actions.getProdutos())
    toast.success('Produto cadastrado com sucesso!')
  } catch (error) {
    yield put({
      type: constants.INSERT_PRODUTO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* updateProdutoSaga(action) {
  try {
    yield call(updateProduto, action.produto)
    yield put({type: constants.UPDATE_PRODUTO.SUCCESS})
    yield put(actions.getProdutos())
    toast.success('Produto atualizado com sucesso!')
  } catch (error) {
    yield put({
      type: constants.UPDATE_PRODUTO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* findProdutoSaga(action) {
  try {
    const {data} = yield call(findProduto, action)
    yield put({type: constants.FIND_PRODUTO.SUCCESS, data: data.data})
  } catch (error) {
    yield put({
      type: constants.FIND_PRODUTO.FAILED,
      error: error,
    })
  }
}

function* deleteProdutoSaga(action) {
  try {
    const {data} = yield call(deleteProduto, action)
    yield put({type: constants.DELETE_PRODUTO.SUCCESS, data: data.data})
    yield put(actions.getProdutos())
    toast.success('Produto removido com sucesso!')
  } catch (error) {
    yield put({
      type: constants.DELETE_PRODUTO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}


export function* saga() {
  yield takeEvery(constants.GET_PRODUTOS.ACTION, getSessoesSaga)
  yield takeEvery(constants.INSERT_PRODUTO.ACTION, insertProdutoSaga)
  yield takeEvery(constants.UPDATE_PRODUTO.ACTION, updateProdutoSaga)
  yield takeEvery(constants.FIND_PRODUTO.ACTION, findProdutoSaga)
  yield takeEvery(constants.DELETE_PRODUTO.ACTION, deleteProdutoSaga)
}
