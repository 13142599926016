import {Reducer} from 'redux'
import {createSagaAction} from '../../shared/sagas/sagas'

// Constants (Action Types)
export const constants = {
  GET_TOKEN_BF: createSagaAction('GET_TOKEN_BF'),
  INSERT_TOKEN_BF: createSagaAction('INSERT_TOKEN_BF'),
  UPDATE_TOKEN_BF: createSagaAction('UPDATE_TOKEN_BF'),
}

/**
 * Data types
 */
export interface TokenBf {
  adm_empresa_id?: number
  token: string

  created_at?: string
  updated_at?: string
}

/**
 * State type
 */
export interface TokenBfState {
  readonly data: TokenBf | null
  readonly loading: boolean
  readonly error: boolean
}

// Reducer
export const initialState: TokenBfState = {
  data:  null,
  loading: false,
  error: false,
}

// Action Creators
export const actions = {
  getTokenBf: () => ({type: constants.GET_TOKEN_BF.ACTION}),
  insertTokenBf: (tokenBf: TokenBf) => ({
    type: constants.INSERT_TOKEN_BF.ACTION,
    tokenBf,
  }),
  updateTokenBf: (tokenBf: TokenBf) => ({
    type: constants.UPDATE_TOKEN_BF.ACTION,
    tokenBf,
  }),
}

export const reducer: Reducer<TokenBfState> = (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_TOKEN_BF.ACTION:
      return {...state, loading: true}
    case constants.GET_TOKEN_BF.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.data,
      }
    case constants.GET_TOKEN_BF.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: null,
      }
    case constants.INSERT_TOKEN_BF.ACTION:
      return {...state, loading: true}
    case constants.INSERT_TOKEN_BF.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.INSERT_TOKEN_BF.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case constants.UPDATE_TOKEN_BF.ACTION:
      return {...state, loading: true}
    case constants.UPDATE_TOKEN_BF.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      }
    case constants.UPDATE_TOKEN_BF.FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default {
  reducer,
  constants,
  actions,
  initialState,
}
