import React from "react";
import { LeftArrow, RightArrow } from "./Pagination_style";

interface PaginationArrowsProps {
    currentPage: number;
    totalPages: number;
    setCurrentPage: (newPage: number) => void;
    direction: "left" | "right";
}

const PaginationArrows: React.FC<PaginationArrowsProps> = ({ currentPage, totalPages, setCurrentPage, direction }: PaginationArrowsProps) => {
    const handleArrowClick = () => {
        if (direction === "left") {
            setCurrentPage(currentPage - 1);
        } else if (direction === "right") {
            setCurrentPage(currentPage + 1);
        }
    };

    return direction === "left" ? (
        <LeftArrow
            visible={currentPage !== 1 && totalPages > 5}
            onClick={handleArrowClick}
        />
    ) : (
        <RightArrow
            visible={currentPage !== totalPages && totalPages > 5}
            onClick={handleArrowClick}
        />
    );
};

export default PaginationArrows;
