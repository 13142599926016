import axios from 'axios'
import {EmpresaConfig} from '.'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const URL_BASE = `${API_URL}/empresa-config`

export function getEmpresaConfig() {
  return axios.get(URL_BASE) 
  .catch(error => ({ error }))
  //.catch((error) => ( console.log(error.response.data.message) )) 
  //.then((response) => console.log(response.data.data))
}

export function updateEmpresaConfig(empresaConfig: EmpresaConfig) {
  return axios.put(URL_BASE+ `/${empresaConfig.adm_empresa_id}`, empresaConfig)
}