import {call, put, takeEvery} from 'redux-saga/effects'
import {constants, actions} from './reducer'
import {getTokenBf, insertTokenBf, updateTokenBf} from './api'
import {toast} from 'react-toastify'
import { ErrorHandler } from '../ErrorHandler'

function* getTokenBfSaga(action) {
  try {
    const {data, error} = yield call(getTokenBf, action)
    if (data)
      yield put({
        type: constants.GET_TOKEN_BF.SUCCESS,
        data: data.data,
      })
    else
      yield put({
        type: constants.GET_TOKEN_BF.FAILED,
        error: error.response.data.message || error,
      })
  } catch (error) {
    yield put({
      type: constants.GET_TOKEN_BF.FAILED,
      error: error,
    })
  }
}

function* insertTokenBfSaga(action) {
  try {
    yield call(insertTokenBf, action.tokenBf)
    yield put({type: constants.INSERT_TOKEN_BF.SUCCESS})
    yield put(actions.getTokenBf())
    toast.success('Token cadastrado com sucesso!')
  } catch (error) {
    yield put({
      type: constants.INSERT_TOKEN_BF.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* updateTokenBfSaga(action) {
  try {
    yield call(updateTokenBf, action.tokenBf)
    yield put({type: constants.UPDATE_TOKEN_BF.SUCCESS})
    yield put(actions.getTokenBf())
    toast.success('Token atualizado com sucesso!')
  } catch (error) {
    yield put({
      type: constants.UPDATE_TOKEN_BF.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

export function* saga() {
  yield takeEvery(constants.GET_TOKEN_BF.ACTION, getTokenBfSaga)
  yield takeEvery(constants.INSERT_TOKEN_BF.ACTION, insertTokenBfSaga)
  yield takeEvery(constants.UPDATE_TOKEN_BF.ACTION, updateTokenBfSaga)
}
