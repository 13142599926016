import {call, put, takeEvery} from 'redux-saga/effects'
import {constants} from './reducer'
import {getReembolsos} from './api'

function* getReembolsosSaga(action) {
  try {
    const {data, error} = yield call(getReembolsos, action.sessao, action.filtros)
    
    if (data)
      yield put({
        type: constants.GET_REEMBOLSOS.SUCCESS,
        data: data,
      })
    else
      yield put({
        type: constants.GET_REEMBOLSOS.FAILED,
        error: error.response.data.message || error,
      })
  } catch (error) {
    yield put({
      type: constants.GET_REEMBOLSOS.FAILED,
      error: error,
    })
  }
}

export function* saga() {
  yield takeEvery(constants.GET_REEMBOLSOS.ACTION, getReembolsosSaga)
}
