import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'api'


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/get-user`
export const FIND_URL = `${API_URL}/api/find`
export const LOGIN_URL = `${API_URL}/api/login-direct`
export const LOGIN_EMPRESA_URL = `${API_URL}/api/login/`
export const REGISTER_URL = `${API_URL}/api/registrar`
export const REQUEST_PASSWORD_URL = `${API_URL}/api/password/recover/`

const USER_AGENT = window.navigator.userAgent
const USE_IP = '127.0.0.1'

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    app: 11,
    email: email,
    password: btoa(password),
  })
}

// Server should return AuthModel
export function loginEmpresa(empresa_id: number, user_id: number) {
  return axios.post(LOGIN_EMPRESA_URL + user_id + '/company', {
    app: 11,
    empresa: empresa_id,
  })
}

// Server should return AuthModel
export function register(nome: string, email: string, cpf: string, password: string) {
  return axios.post(REGISTER_URL, {
    app: 11,
    nome,
    cpf: cpf,
    'e-mail': email,
    usuario: {
      senha: btoa(password),
      doc: cpf,
    },
    client: {
      user_agent: USER_AGENT,
      ip: USE_IP,
    },
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get(GET_USER_BY_ACCESSTOKEN_URL)
}
