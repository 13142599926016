import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/pt'

import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import ptMessages from './messages/pt.json'
const allMessages = {
  "pt-BR": ptMessages,
  en: enMessages,
  es: esMessages,
}

const I18nProvider = ({ children }: { children: any }) => {
  const locale = useLang()
  const messages = allMessages[locale]

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
