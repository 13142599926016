import {createReducer} from '../../shared/reducers/reducers'
import {createSagaAction} from '../../shared/sagas/sagas'

// Constants (Action Types)
export const constants = {
  GET_SESSOES: createSagaAction('GET_SESSOES'),
  GET_SESSOES_LEGADO: createSagaAction('GET_SESSOES_LEGADO'),
  FIND_SESSAO: createSagaAction('FIND_SESSAO'),
  INSERT_SESSAO: createSagaAction('INSERT_SESSAO'),
  UPDATE_SESSAO: createSagaAction('UPDATE_SESSAO'),
}

/**
 * Data types
 */
export interface Sessao {
  id?: number
  adm_empresa_id?: number
  nome: string
  inicio: string
  termino: string
  
  info?: {}
  sessao_bf?: {}

  created_at?: string
  updated_at?: string
  deleted_at?: string
}

export interface InfoSessao {
  informacao_legal?: string
  parametros?: {}

  banner?: string
  logo?: string
  background?: string
}

export interface SessaoLegado {
  cod_sessao: number,
  descricao: string,
  dtInicio:string,
  dtTermino:string,
}

/**
 * State type
 */
export interface SessaoState {
  readonly data: Sessao[]
  readonly obj: Sessao | []
  readonly loading: boolean
  readonly error: boolean
  readonly dataLegado: SessaoLegado[] | null
}

// Reducer
export const initialState: SessaoState = {
  data: [],
  obj: [],
  loading: false,
  error: false,
  dataLegado: null,
}

// Action Creators
export const actions = {
  getSessoes: (page: number) => ({type: constants.GET_SESSOES.ACTION, page}),
  findSessao: (id: string) => ({type: constants.FIND_SESSAO.ACTION, id}),
  insertSessao: (sessao: Sessao) => ({
    type: constants.INSERT_SESSAO.ACTION,
    sessao,
  }),
  updateSessao: (sessao: Sessao) => ({
    type: constants.UPDATE_SESSAO.ACTION,
    sessao,
  }),
  getSessoesLegado: () => ({type: constants.GET_SESSOES_LEGADO.ACTION}),
}

const actionHandlers = {
  [constants.GET_SESSOES.ACTION]: (state: SessaoState) => ({...state, loading: true}),
  [constants.GET_SESSOES.SUCCESS]: (state: SessaoState, action: any) => ({
    ...state,
    loading: false,
    error: false,
    data: action.data
  }),
  [constants.GET_SESSOES.FAILED]: (state: SessaoState) => ({
    ...state,
    loading: false,
    error: true,
    data: [],
  }),
  
  [constants.INSERT_SESSAO.ACTION]: (state: SessaoState) => ({...state, loading: true}),
  [constants.INSERT_SESSAO.SUCCESS]: (state: SessaoState) => ({
    ...state,
    loading: false,
    error: false,
  }),
  [constants.INSERT_SESSAO.FAILED]: (state: SessaoState) => ({
    ...state,
    loading: false,
    error: true,
  }),

  [constants.UPDATE_SESSAO.ACTION]: (state: SessaoState) => ({...state, loading: true}),
  [constants.UPDATE_SESSAO.SUCCESS]: (state: SessaoState) => ({
    ...state,
    loading: false,
    error: false,
  }),
  [constants.UPDATE_SESSAO.FAILED]: (state: SessaoState) => ({
    ...state,
    loading: false,
    error: true,
  }),

  [constants.FIND_SESSAO.ACTION]: (state: SessaoState) => ({...state, loading: true}),
  [constants.FIND_SESSAO.SUCCESS]: (state: SessaoState, action: any) => ({
    ...state,
    loading: false,
    error: false,
    obj: action.data,
  }),
  [constants.FIND_SESSAO.FAILED]: (state: SessaoState) => ({
    ...state,
    loading: false,
    error: true,
  }),

  [constants.GET_SESSOES_LEGADO.ACTION]: (state: SessaoState) => ({...state, loading: true}),
  [constants.GET_SESSOES_LEGADO.SUCCESS]: (state: SessaoState, action: any) => ({
    ...state,
    loading: false,
    error: false,
    dataLegado: action.data,
  }),
  [constants.GET_SESSOES_LEGADO.FAILED]: (state: SessaoState) => ({
    ...state,
    loading: false,
    error: true,
    dataLegado: [],
  }),

}

export const reducer = createReducer(initialState, (state: SessaoState, action: any) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : {...state}
})

// export const reducer: Reducer<SessaoState> = (state = initialState, action) => {
//   switch (action.type) {
//     case constants.GET_SESSOES.ACTION:
//       return {...state, loading: true}
//     case constants.GET_SESSOES.SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         error: false,
//         data: action.data,
//       }
//     case constants.GET_SESSOES.FAILED:
//       return {
//         ...state,
//         loading: false,
//         error: true,
//         data: [],
//       }
//     case constants.INSERT_SESSAO.ACTION:
//       return {...state, loading: true}
//     case constants.INSERT_SESSAO.SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         error: false,
//       }
//     case constants.INSERT_SESSAO.FAILED:
//       return {
//         ...state,
//         loading: false,
//         error: true,
//       }
//     case constants.UPDATE_SESSAO.ACTION:
//       return {...state, loading: true}
//     case constants.UPDATE_SESSAO.SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         error: false,
//       }
//     case constants.UPDATE_SESSAO.FAILED:
//       return {
//         ...state,
//         loading: false,
//         error: true,
//       }
//     case constants.FIND_SESSAO.ACTION:
//       return {...state, loading: true, obj: []}
//     case constants.FIND_SESSAO.SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         error: false,
//         obj: action.data,
//       }
//     case constants.FIND_SESSAO.FAILED:
//       return {
//         ...state,
//         loading: false,
//         error: true,
//       }
//     default:
//       return state
//   }
// }

export default {
  reducer,
  constants,
  actions,
  initialState,
}
