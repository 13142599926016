import {call, put, takeEvery} from 'redux-saga/effects'
import {constants, actions} from './reducer'
import {
  getCategorias,
  insertCategoria,
  findCategoria,
  updateCategoria,
  deleteCategoria,
} from './api'
import {toast} from 'react-toastify'
import { ErrorHandler } from '../ErrorHandler'

function* getCategoriasSaga(action) {
  try {
    const {data} = yield call(getCategorias, action)
    yield put({
      type: constants.GET_CATEGORIAS.SUCCESS,
      data: data.data,
    })
  } catch (error) {
    yield put({
      type: constants.GET_CATEGORIAS.FAILED,
      error: error,
    })
  }
}

function* insertCategoriaSaga(action) {
  try {
    yield call(insertCategoria, action.categoria)
    yield put({type: constants.INSERT_CATEGORIA.SUCCESS})
    yield put(actions.getCategorias())
    toast.success('Categoria cadastrada com sucesso!')
  } catch (error) {
    console.log(error)
    yield put({
      type: constants.INSERT_CATEGORIA.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* updateCategoriaSaga(action) {
  try {
    yield call(updateCategoria, action.categoria)
    yield put({type: constants.UPDATE_CATEGORIA.SUCCESS})
    yield put(actions.getCategorias())
    toast.success('Categoria atualizada com sucesso!')
  } catch (error) {
    yield put({
      type: constants.UPDATE_CATEGORIA.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* findCategoriaSaga(action) {
  try {
    const {data} = yield call(findCategoria, action)
    yield put({type: constants.FIND_CATEGORIA.SUCCESS, data: data.data})
  } catch (error) {
    yield put({
      type: constants.FIND_CATEGORIA.FAILED,
      error: error,
    })
  }
}

function* deleteCategoriaSaga(action) {
  try {
    const {data} = yield call(deleteCategoria, action)
    yield put({type: constants.DELETE_CATEGORIA.SUCCESS, data: data.data})
    yield put(actions.getCategorias())
    toast.success('Categoria removida com sucesso!')
  } catch (error) {
    yield put({
      type: constants.DELETE_CATEGORIA.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

export function* saga() {
  yield takeEvery(constants.GET_CATEGORIAS.ACTION, getCategoriasSaga)
  yield takeEvery(constants.INSERT_CATEGORIA.ACTION, insertCategoriaSaga)
  yield takeEvery(constants.UPDATE_CATEGORIA.ACTION, updateCategoriaSaga)
  yield takeEvery(constants.FIND_CATEGORIA.ACTION, findCategoriaSaga)
  yield takeEvery(constants.DELETE_CATEGORIA.ACTION, deleteCategoriaSaga)
}
