import React, { Suspense } from 'react'
import { BrowserRouter as ReactBrowserRouter, BrowserRouterProps } from 'react-router-dom'
import { I18nProvider } from '../components/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../components/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import FiltersCtxWrapper from './pages/ReembolsosDashboard/context/FiltersCtx'
import ModalCtxWrapper from './pages/ReembolsosDashboard/context/ModalCtx'
import PendingRowsCtxWrapper from './pages/ReembolsosDashboard/context/PendingRowsCtx'
import ReembolsosCtxWrapper from './pages/ReembolsosDashboard/context/ReembolsosCtx'
import ScreenSizeCtxWrapper from './pages/ReembolsosDashboard/context/ScreenSizeCtx'
import SearchCtxWrapper from './pages/ReembolsosDashboard/context/SearchCtx'
import SelectedRowsCtxWrapper from './pages/ReembolsosDashboard/context/SelectedRowsCtx'
import { Routes } from './routing/Routes'
import InsightsCtxWrapper from './pages/ReembolsosDashboard/context/InsightsCtx'

type Props = {
  basename: string
}

interface BrowserRouterWithChildrenProps extends BrowserRouterProps {
  children: React.ReactNode
}

const BrowserRouter: React.FC<BrowserRouterWithChildrenProps> = ({ children, ...props }) => {
  return (
    <ReactBrowserRouter {...props}>
      {children}
    </ReactBrowserRouter>
  )
}

const App: React.FC<Props> = ({ basename }) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <ScreenSizeCtxWrapper>
          <FiltersCtxWrapper>
            <ReembolsosCtxWrapper>
              <InsightsCtxWrapper>
                <PendingRowsCtxWrapper>
                  <SelectedRowsCtxWrapper>
                    <ModalCtxWrapper>
                      <SearchCtxWrapper>
                        <I18nProvider>
                          <LayoutProvider>
                            <AuthInit>
                              <Routes />
                            </AuthInit>
                          </LayoutProvider>
                        </I18nProvider>
                      </SearchCtxWrapper>
                    </ModalCtxWrapper>
                  </SelectedRowsCtxWrapper>
                </PendingRowsCtxWrapper>
              </InsightsCtxWrapper>
            </ReembolsosCtxWrapper>
          </FiltersCtxWrapper>
        </ScreenSizeCtxWrapper>
      </BrowserRouter>
    </Suspense >
  )
}

export { App }
