import {call, put, takeEvery} from 'redux-saga/effects'
import {constants, actions} from './reducer'
import {getEmpresaConfig, updateEmpresaConfig} from './api'
import {toast} from 'react-toastify'
import { ErrorHandler } from '../ErrorHandler'

function* getEmpresaConfigSaga(action) {
  try {
    const {data, error} = yield call(getEmpresaConfig, action)
    if (data)
      yield put({
        type: constants.GET_EMPRESA_CONFIG.SUCCESS,
        data: data.data,
      })
    else
      yield put({
        type: constants.GET_EMPRESA_CONFIG.FAILED,
        error: error.response.data.message || error,
      })
  } catch (error) {
    yield put({
      type: constants.GET_EMPRESA_CONFIG.FAILED,
      error: error,
    })
  }
}

function* updateEmpresaConfigSaga(action) {
  try {
    yield call(updateEmpresaConfig, action.empresaConfig)
    yield put({type: constants.UPDATE_EMPRESA_CONFIG.SUCCESS})
    yield put(actions.getEmpresaConfig())
    toast.success('Configurações atualizadas com sucesso!')
  } catch (error) {
    yield put({
      type: constants.UPDATE_EMPRESA_CONFIG.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

export function* saga() {
  yield takeEvery(constants.GET_EMPRESA_CONFIG.ACTION, getEmpresaConfigSaga)
  yield takeEvery(constants.UPDATE_EMPRESA_CONFIG.ACTION, updateEmpresaConfigSaga)
}
