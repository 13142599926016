/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {KTSVG} from '../../../../components/helpers'
import {RootState} from '../../../../setup'
import { loginEmpresa } from '../redux/AuthApi'
import * as auth from '../redux/AuthRedux'

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Empresas() {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState("")
  const dispatch = useDispatch()
  const empresas = useSelector<RootState>(({auth}) => auth.empresas, shallowEqual) as [{
    id: number
    nome: string
  }]
  const pessoa = useSelector<RootState>(({auth}) => auth.pessoa, shallowEqual) as {
    id: number
  }

  const selecionarEmpresa = (id: number) => {
    setLoading(true)
    loginEmpresa(id, pessoa.id).then(({data}) => {
      dispatch(auth.actions.login(data.auth.token.access_token))
    }).catch(() => {
      setLoading(false)
      setStatus('Os detalhes de login estão incorretos!')
    })
  }

  return (
    <>
      <div className='text-center mb-5 mb-lg-20'>
        <h3 className='fs-2 mt-8'>Empresas</h3>
        <p className='text-muted font-weight-bold'>Selecione a empresa que deseja administrar</p>
      </div>
      {status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{status}</div>
        </div>
      )}
      <ul className='list-group'>
        {empresas?.map((empresa) => (
          <li className='list-group-item d-flex justify-content-between align-items-center fw-bold fs-6 py-4'>
            {empresa?.nome}
            <button className='btn btn-sm btn-icon btn-primary' onClick={() => selecionarEmpresa(empresa?.id as number)}>
              <KTSVG path='/media/icons/duotune/arrows/arr064.svg'></KTSVG>
            </button>
          </li>
        ))}
      </ul>
      <div className="text-center mt-3">
      {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Carregando ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
      </div>
    </>
  )
}
