import axios from 'axios'
import {Categoria} from '.'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const URL_BASE = `${API_URL}/categoria`

export function getCategorias() {
  return axios.get(URL_BASE) //.then((response) => console.log(response.data.data))
}

export function findCategoria(action: any) {
  return axios.get(URL_BASE + `/${action.id}`)
}

export function insertCategoria(categoria: Categoria) {
  return axios.post(URL_BASE, categoria)
}

export function updateCategoria(categoria: Categoria) {
  return axios.put(URL_BASE + `/${categoria.id}`, categoria)
}

export function deleteCategoria(action: any) {
  return axios.delete(URL_BASE + `/${action.id}`)
}