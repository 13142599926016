import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl, toDateFormat, toDateFormatAlt} from '../../../../components/helpers'

const SessaoCard = (obj: any) => {
  const {sessao} = obj
  return (
    <div
      className='card card-xl-stretch h-225px border border-hover border-2 border-primary shadow-sm'
      style={{
        borderRadius: '1rem',
        backgroundImage: `url('${
          sessao.info?.background_path !== undefined
            ? sessao.info?.background_path
            : toAbsoluteUrl('/media/misc/fundo.png')
        }')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className='card-body p-3 d-flex flex-column justify-content-between'>
        <div className='overlay overflow-hidden rounded flex-grow h-100 mb-3 d-flex align-items-center justify-content-center'>
          <div className='overlay-wrapper px-10'>
            <img
              src={
                sessao.info?.banner_path !== undefined
                  ? sessao.info?.banner_path
                  : toAbsoluteUrl('/media/misc/interrogacao.png')
              }
              alt=''
              className='mw-100 mh-100px rounded'
            />
          </div>
          <div className='overlay-layer bg-light bg-opacity-10'>
            {/* <a href='#' className='btn btn-icon btn-light-dark shadow' title='Relatorios'>
              <KTSVG path='/media/icons/old/Shopping/Chart-bar1.svg' className='svg-icon-1' />
            </a> */}
            {/* <Link
              title='Produtos do evento'
              className={`btn btn-icon btn-light-dark shadow ms-6`}
              to={`/sessao/${sessao.id}/produto`}
            >
              <KTSVG path='/media/icons/old/Shopping/Box3.svg' className='svg-icon-2x' />
            </Link> */}
            {/* <Link
              title='Ajustes'
              className={`btn btn-icon btn-light-dark shadow ms-6`}
              to={`/sessao/${sessao.id}/edit`}
            >
              <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2x' />
            </Link> */}
          </div>
        </div>
        <div className='bg-light p-3 rounded'>
          <div className='text-dark fw-bolder fs-5'>{sessao.nome}</div>
          <div className='text-dark fs-8'> {toDateFormatAlt(sessao.inicio.replace(" ", "T") as string)} </div>
        </div>
      </div>
    </div>
  )
}

export default SessaoCard
