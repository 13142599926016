/* eslint-disable jsx-a11y/anchor-is-valid */
import {Component, FC, useContext, useState} from 'react'
import {connect, ConnectedProps} from 'react-redux'
import {bindActionCreators, Dispatch} from 'redux'
import {RootState} from '../../../setup'
import {actions, Produto} from '../../../store/produto'
import {actions as actionsCategoria} from '../../../store/categoria'
import {toAbsoluteUrl, toCurrencyFormat, toDateFormat} from '../../../components/helpers'
import ProdutoContext from '../../../contexts/ProdutoContext'
import {FormModalProduto} from './FormModalProduto'
import '../../../components/assets/sass/plugins.scss'

const ProdutoPage: FC = () => {
  const initialValues: Produto = {
    nome: '',
    valor: 0,
    imagem: '',
  }

  const [obj, setObj] = useState(initialValues)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)

  const {reducer} = useContext(ProdutoContext)
  const {produto, loading} = reducer

  function deleteProduto(id: number) {
    if (window.confirm('Tem certeza que deseja excluir esse produto?')) reducer.deleteProduto(id)
  }

  function showForm(item: Produto | null) {
    setObj(item == null ? initialValues : item)
    handleShow()
  }

  return (
    <>
      <FormModalProduto produto={obj} show={show} setShow={setShow} />
      <div className='card card-custom gutter-b mt-6'>
        <div className='card-header border-0 py-5 pb-0'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='fw-bolder mb-2 text-dark'>Produtos</span>
          </h3>
          <div className='card-toolbar'>
            <a
              href='javascript:;'
              className='btn btn-sm btn-light-primary'
              onClick={() => {
                showForm(null)
              }}
            >
              Novo produto
            </a>
          </div>
        </div>
        <div className='card-body p-5 m-5 rounded blockui' id='body-produtos'>
          <div className='row'>
            <table className='table gs-7 gy-7 gx-7'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                  <th className='w-45px'></th>
                  <th>Nome</th>
                  <th>Valor</th>
                  <th>Criado em</th>
                  <th>Atualizado em</th>
                  <th className='text-end'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {produto?.map((produto: Produto) => (
                  <tr className='align-middle'>
                    <td>
                      <div className='symbol symbol-45px cursor-pointer'>
                        <div
                          className='symbol-label'
                          style={{
                            backgroundImage: `url('${
                              produto.imagem !== undefined
                                ? produto.imagem
                                : toAbsoluteUrl('/media/misc/sem-imagem.png')
                            }')`,
                          }}
                        ></div>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
                      >
                        {produto.nome}
                      </a>
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {produto.categoria?.nome}
                      </span>
                    </td>

                    <td> {toCurrencyFormat(produto.valor)}</td>
                    <td>{toDateFormat(produto.created_at as string)}</td>
                    <td>{toDateFormat(produto.updated_at as string)}</td>
                    <td className='text-end'>
                      <a
                        onClick={() => {
                          showForm(produto)
                        }}
                        href='#'
                        className='btn btn-sm btn-light-primary me-2'
                      >
                        Alterar
                      </a>
                      <a
                        href='#'
                        className='btn btn-sm btn-light-danger'
                        onClick={() => deleteProduto(produto.id as number)}
                      >
                        Excluir
                      </a>
                    </td>
                  </tr>
                ))}
                {!loading && produto.length === 0 && (
                  <tr>
                    <td className='text-center text-gray-400' colSpan={6}>
                      Nenhum produto encontrado!
                    </td>
                  </tr>
                )}
                {loading && (
                  <tr>
                    <td className='text-center text-gray-400' colSpan={6}>
                      <span className='spinner-border align-middle me-2 mt-1'></span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

class ProdutoWrapper extends Component<PropsFromRedux> {
  componentDidMount() {
    this.props.getProdutos()
    this.props.getCategorias()
  }

  render() {
    return (
      <ProdutoContext.Provider value={{reducer: this.props}}>
        <ProdutoPage />
      </ProdutoContext.Provider>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  loading: state.produto.loading,
  produto: state.produto.data,
  categoria: state.categoria.data,
})
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(Object.assign({}, actions, actionsCategoria), dispatch)
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ProdutoWrapper)
