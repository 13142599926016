/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../components/layout/core'
import { FeedsWidget1 } from '../../../components/partials/widgets'
import DashboardPage from './DashboardPage'

const DashboardWrapper: React.FC = () => {
  const intl = useIntl()
  // TODO Pq se tirar o FeedsWidget1 modal para de abrir? 
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />

      <FeedsWidget1 className=''/>
    </>
  )
}

export {DashboardWrapper}
