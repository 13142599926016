import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const ModalCtx = createContext<any>(undefined);

export enum Screen {
    SingleRowClick = 0,
    Approve = 1,
    Approved = 2,
    Refuse = 3,
    Error = 4,
}

export enum SingleRowAction {
    Refusing = 0,
    Approving = 1,
    ReApproving = 2,
    Cancelling = 3
}

export interface Modal {
    isOpen: boolean;
    idList: number[];
    value: string;
    acaoPor: string;
    screen: Screen | undefined;
    selectedAmount: string;
    situacao?: number | undefined;
    singleRowClick?: {
        action: SingleRowAction | undefined;
        rowData: {
            username: string;
            hexcode: string;
            date: string;
        };
    };
}

const ModalCtxWrapper = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const [modal, setModal] = useState<Modal>({
        isOpen: false,
        idList: [],
        value: "",
        acaoPor: "",
        screen: undefined,
        selectedAmount: "",
        situacao: undefined,
        singleRowClick: {
            action: undefined,
            rowData: {
                username: "",
                hexcode: "",
                date: "",
            },
        },
    });

    const resetModalCtx = () => {
        setModal({
            isOpen: false,
            idList: [],
            value: "",
            acaoPor: "",
            screen: undefined,
            selectedAmount: "",
            situacao: undefined,
            singleRowClick: {
                action: undefined,
                rowData: {
                    username: "",
                    hexcode: "",
                    date: "",
                },
            },
        });
    };

    const closeModal = () => resetModalCtx();

    useEffect(() => {
        const isReembolsosUrl = location.pathname.match(/^\/sessao\/\d+\/reembolsos$/);

        if (!isReembolsosUrl) {
            resetModalCtx();
        }
    }, [location.pathname]);

    return (
        <ModalCtx.Provider value={{ modal, setModal, closeModal }}>
            {children}
        </ModalCtx.Provider>
    );
};

export default ModalCtxWrapper;
