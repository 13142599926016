/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../components/helpers'
import CategoriaContext from '../../../contexts/CategoriaContext'
import {Categoria} from '../../../store/categoria'
import {Modal} from 'react-bootstrap-v5'

const createSchema = Yup.object().shape({
  nome: Yup.string()
    .min(2, 'Minino de 2 caracteres!')
    .max(45, 'Maximo de 45 caracteres!')
    .required('O nome da categoria é um campo obrigatório'),
})

type Props = {
  categoria: Categoria
  show: boolean
  setShow: any
}

const FormModalCategoria: FC<Props> = ({categoria, show, setShow}) => {
  const {reducer} = useContext(CategoriaContext)
  const [loading] = useState(false)
  const [currentSchema] = useState(createSchema)
  const handleClose = () => setShow(false)

  const submitStep = (values: Categoria, actions: any) => {
    values?.id ? reducer.updateCategoria(values) : reducer.insertCategoria(values)

    actions.setSubmitting(false)
    actions.resetForm()
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
    >
      <div className='modal-content'>
        <div className='modal-header border-0 pb-0'>
          <h2></h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => {
              handleClose()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          {categoria?.id ? (
            <div className='mb-15 text-center'>
              <h2 className='mb-2'>
                Alterar a categoria <mark>{categoria.nome}</mark>
              </h2>
              <div className='text-muted fw-bold fs-6'>
                Alterar a categoria que será vinculada em produtos!
              </div>
            </div>
          ) : (
            <div className='mb-15 text-center'>
              <h2 className='mb-2'>
                Cadastro de uma nova <mark>categoria</mark>
              </h2>
              <div className='text-muted fw-bold fs-6'>
                Criar uma nova categoria que será vinculada em produtos!
              </div>
            </div>
          )}

          <div
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_produto_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={currentSchema}
                initialValues={categoria}
                enableReinitialize={true}
                onSubmit={submitStep}
              >
                {() => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Nome</span>
                          <i
                            className='fas fa-exclamation-circle ms-2 fs-7'
                            data-bs-toggle='tooltip'
                            title='Nome para nova categoria'
                          ></i>
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='nome'
                          placeholder='Insira o nome para nova categoria'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='nome' />
                        </div>
                      </div>

                    <div className='d-flex justify-content-end pt-10'>
                      <button type='submit' className='btn btn-lg btn-primary'>
                        {!loading && (
                          <span className='indicator-label'>
                            Salvar
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr084.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Carregando...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {FormModalCategoria}
