import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as sessao from '../../store/sessao'
import * as imagem from '../../store/imagem'
import * as produto from '../../store/produto'
import * as categoria from '../../store/categoria'
import * as sessaoProduto from '../../store/sessaoProduto'
import * as tokenBf from '../../store/tokenBf'
import * as reembolso from '../../store/reembolso'
import * as empresaConfig from '../../store/empresaConfig'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  sessao: sessao.reducer,
  imagem: imagem.reducer,
  produto: produto.reducer,
  categoria: categoria.reducer,
  sessaoProduto: sessaoProduto.reducer,
  tokenBf: tokenBf.reducer,
  reembolso: reembolso.reducer,
  empresaConfig: empresaConfig.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    sessao.saga(),
    imagem.saga(),
    produto.saga(),
    categoria.saga(),
    sessaoProduto.saga(),
    tokenBf.saga(),
    reembolso.saga(),
    empresaConfig.saga()
  ])
}
