import axios from 'axios'
import {TokenBf} from '.'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const URL_BASE = `${API_URL}/token-bf`

export function getTokenBf() {
  return axios.get(URL_BASE) 
  .catch(error => ({ error }))
  //.catch((error) => ( console.log(error.response.data.message) )) 
  //.then((response) => console.log(response.data.data))
}

export function insertTokenBf(tokenBf: TokenBf) {
  return axios.post(URL_BASE, tokenBf)
}

export function updateTokenBf(tokenBf: TokenBf) {
  return axios.put(URL_BASE+ `/${tokenBf.adm_empresa_id}`, tokenBf)
}

export function deleteTokenBf(action: any) {
  return axios.delete(URL_BASE + `/${action.id}`)
}