import {call, put, takeEvery} from 'redux-saga/effects'
import {constants, actions} from './reducer'
import {
  getSessaoProdutos,
  getSugestoes,
  insertSessaoProduto,
  updateSessaoProduto,
  deleteSessaoProduto,
} from './api'
import {toast} from 'react-toastify'
import { ErrorHandler } from '../ErrorHandler'

function* getSessoesSaga(action) {
  try {
    const {data} = yield call(getSessaoProdutos, action)
    yield put({
      type: constants.GET_SESSAO_PRODUTOS.SUCCESS,
      data: data.data,
    })
  } catch (error) {
    yield put({
      type: constants.GET_SESSAO_PRODUTOS.FAILED,
      error: error,
    })
  }
}

function* insertSessaoProdutoSaga(action) {
  try {
    yield call(insertSessaoProduto, action.sessaoProduto)
    yield put({type: constants.INSERT_SESSAO_PRODUTO.SUCCESS})
    yield put(actions.getSessaoProdutos(action.sessaoProduto.pub_sessao_id))
    yield put(actions.getSugestoes(action.sessaoProduto.pub_sessao_id))
    toast.success('Produto adicionado ao evento com sucesso!')
  } catch (error) {
    yield put({
      type: constants.INSERT_SESSAO_PRODUTO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* updateSessaoProdutoSaga(action) {
  try {
    yield call(updateSessaoProduto, action.sessaoProduto)
    yield put({type: constants.UPDATE_SESSAO_PRODUTO.SUCCESS})
    yield put(actions.getSessaoProdutos(action.sessaoProduto.pub_sessao_id))
    toast.success('Produto atualizado ao evento com sucesso!')
  } catch (error) {
    yield put({
      type: constants.UPDATE_SESSAO_PRODUTO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

function* getSugestoesSaga(action) {
  try {
    const {data} = yield call(getSugestoes, action)
    yield put({
      type: constants.GET_SUGESTOES_PRODUTOS.SUCCESS,
      data: data.data,
    })
  } catch (error) {
    yield put({
      type: constants.GET_SUGESTOES_PRODUTOS.FAILED,
      error: error,
    })
  }
}

function* deleteSessaoProdutoSaga(action) {
  try {
    const {data} = yield call(deleteSessaoProduto, action)
    yield put({type: constants.DELETE_SESSAO_PRODUTO.SUCCESS, data: data.data})
    yield put(actions.getSessaoProdutos(action.sessao_id))
    yield put(actions.getSugestoes(action.sessao_id))
    toast.success('Produto removido com sucesso do evento!')
  } catch (error) {
    yield put({
      type: constants.DELETE_SESSAO_PRODUTO.FAILED,
      error: error,
    })
    ErrorHandler(error);
  }
}

export function* saga() {
  yield takeEvery(constants.GET_SESSAO_PRODUTOS.ACTION, getSessoesSaga)
  yield takeEvery(constants.INSERT_SESSAO_PRODUTO.ACTION, insertSessaoProdutoSaga)
  yield takeEvery(constants.UPDATE_SESSAO_PRODUTO.ACTION, updateSessaoProdutoSaga)
  yield takeEvery(constants.GET_SUGESTOES_PRODUTOS.ACTION, getSugestoesSaga)
  yield takeEvery(constants.DELETE_SESSAO_PRODUTO.ACTION, deleteSessaoProdutoSaga)
}
