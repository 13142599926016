/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {Produto} from '../../../store/produto'
import {KTSVG} from '../../../components/helpers'
import ProdutoContext from '../../../contexts/ProdutoContext'
import {AutoCompleteField} from '../../../components/helpers/components/AutoCompleteField'
import {ImagemUpload} from '../../../components/helpers/components/ImagemUpload'
import {Modal} from 'react-bootstrap-v5'

const createSchema = Yup.object().shape({
  nome: Yup.string()
    .min(2, 'Minino de 2 caracteres!')
    .max(45, 'Maximo de 45 caracteres!')
    .required('O nome do produto é um campo obrigatório'),
  valor: Yup.string().required('O valor do produto é um campo obrigatório'),
})

type Props = {
  produto: Produto
  show: boolean
  setShow: any
}

const FormModalProduto: FC<Props> = ({produto, show, setShow}) => {
  const {reducer} = useContext(ProdutoContext)
  const [loading] = useState(false)
  const [currentSchema] = useState(createSchema)
  const handleClose = () => setShow(false)

  const submitStep = (values: Produto, actions: any) => {
    values?.id ? reducer.updateProduto(values) : reducer.insertProduto(values)

    actions.setSubmitting(false)
    actions.resetForm()
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
    >
      <div className='modal-content'>
        <div className='modal-header border-0 pb-0'>
          <h2></h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => {
              handleClose()
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          {produto?.id ? (
            <div className='mb-15 text-center'>
              <h2 className='mb-2'>
                Alterar o produto <mark>{produto.nome}</mark>
              </h2>
              <div className='text-muted fw-bold fs-6'>
                Alterar o produto a ser consumido em seus eventos!
              </div>
            </div>
          ) : (
            <div className='mb-15 text-center'>
              <h2 className='mb-2'>
                Cadastro de um novo <mark>produto</mark>
              </h2>
              <div className='text-muted fw-bold fs-6'>
                Criar um novo produto a ser consumido em seus eventos!
              </div>
            </div>
          )}

          <div
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_produto_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={currentSchema}
                initialValues={produto}
                enableReinitialize={true}
                onSubmit={submitStep}
              >
                {() => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    <div className='fv-row mb-10'>
                      <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                        <span className='required'>Nome do produto</span>
                      </label>
                      <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='nome'
                        placeholder='Insira o nome do produto'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name='nome' />
                      </div>
                    </div>

                    <div className='row mb-10'>
                      <div className='col-md-6'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className=''>Categoria</span>
                        </label>
                        <Field
                          name={'categoria'}
                          component={AutoCompleteField}
                          options={reducer.categoria}
                          placeholder='Selecione uma categoria'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='categoria' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Valor</span>
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='valor'
                          placeholder='Valor'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='valor' />
                        </div>
                      </div>
                    </div>

                    <div className='row mb-10'>
                      <div className='col-md-12'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Imagem</span>
                        </label>
                        <Field name={'imagem'} component={ImagemUpload} />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='imagem' />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-end pt-10'>
                      <button type='submit' className='btn btn-lg btn-primary'>
                        {!loading && (
                          <span className='indicator-label'>
                            Salvar
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr084.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Carregando...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {FormModalProduto}
