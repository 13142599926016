/* eslint-disable jsx-a11y/anchor-is-valid */
import {FieldProps} from 'formik'
import React, {useState} from 'react'
import Dropzone from 'react-dropzone'
import '../../assets/sass/plugins.scss'
import {insertImagem} from '../../../store/imagem'
import { toast } from 'react-toastify'
import { ErrorHandler } from '../../../store/ErrorHandler'

type Props = {
  seletor: string
  setImage?: Function | undefined
}

function getBase64(file: File, cb: any) {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
  }
}

const ImagemUpload: React.FC<Props & FieldProps> = ({field, form, setImage = undefined}) => {
  const [fileName, setFileName] = useState(0)
  const onDrop = (files: any) => {
    files.forEach((file: any) => {
      const imagem = {tags: [field.name], data: ''}

      getBase64(file, (result: any) => {
        imagem['data'] = result
        const retorno = insertImagem(imagem).then((res) => {
          form.setFieldValue(field.name, res.data.filename)

          if (setImage !== undefined) setImage(res.data.path)
          else setFileName(res.data.path)
        }).catch((error) => {
          ErrorHandler(error);
        });
        
      })
    })
  }

  const onDropImagem = (files: any) => {
    onDrop(files)
  }

  return (
    <>
      <Dropzone onDrop={onDropImagem} accept='image/*'>
        {({getRootProps, getInputProps}) => (
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <div className='dz-message needsclick'>
              <i className='bi bi-file-earmark-arrow-up text-primary fs-3x'></i>
              <div className='ms-4'>
                <h3 className='fs-5 fw-bolder text-gray-900 mb-1'>
                  Solte os arquivos aqui ou clique para fazer o upload.
                </h3>
                {!fileName && (
                  <span className='fs-8 fw-bold text-gray-400'>
                    Apenas imagens são aceitas. O tamanho máximo do arquivo é 10MB.
                  </span>
                )}
                {fileName !== 0 && (
                  <span className='mt-3 fs-8 fw-bold text-gray-400 d-flex flex-column align-items-center'>
                    <div className='symbol symbol-45px cursor-pointer'>
                      <div
                        className='symbol-label'
                        style={{
                          backgroundImage: `url('${fileName}')`,
                        }}
                      ></div>
                    </div>
                    {fileName}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </>
  )
}

export {ImagemUpload}
