/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-350px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={user.imagemLink} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.nome} {user.sobrenome}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <div className='d-flex py-2'>
            <div className='symbol symbol-40px bg-light me-3'>
              <div className='symbol-label'>
                <KTSVG
                  path='/media/icons/old/Communication/Add-user.svg'
                  className='svg-icon-3 svg-icon-warning'
                />
              </div>
            </div>
            <div className='navi-text'>
              <div className='fw-bold text-dark'>Adicionar outra conta</div>
              <div className='text-muted fs-8'>Login com usuário ou empresa diferente</div>
            </div>
          </div>
        </a>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to='/logout' className='menu-link px-5'>
          <div className='d-flex py-2'>
            <div className='symbol symbol-40px bg-light me-3'>
              <div className='symbol-label'>
                <KTSVG
                  path='/media/icons/old/Home/Door-open.svg'
                  className='svg-icon-3 svg-icon-gray-700'
                />
              </div>
            </div>
            <div className='navi-text'>
              <div className='fw-bold text-dark'>Sair</div>
              <div className='text-muted fs-8'>Desconectar de sua conta</div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
