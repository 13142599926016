import { FormikErrors } from 'formik';
import { toast } from 'react-toastify';

type CustomError = {
    response?: {
        status?: number;
        data?: {
            errors?: Record<string, string[]>;
            message?: string;
        };
    };
};

type ErrorObject = {
    [key: string]: string | string[];
};

export const ErrorHandler = (error: CustomError) => {
    if (error.response && error.response.status === 422) {
        let errorMessage = "";
        let errors = error.response.data?.errors;

        function joinErrorMessages(errors: ErrorObject) {
            for (const key in errors) {
                if (Array.isArray(errors[key])) {
                    errorMessage += (errors[key] as string[]).join("\n") + "\n";
                } else {
                    errorMessage += (errors[key] as string) + "\n";
                }
            }
        }

        joinErrorMessages(errors || {});

        toast.error(errorMessage || error.response.data?.message);
    } else {
        toast.error(error.response?.data?.message || "An unexpected error occurred");
    }
};

export function handleErrorsByKey<T>(
    error: CustomError,
    keys: (keyof T)[],
): FormikErrors<T> {
    const errors = error.response?.data?.errors

    if (!errors || errors.message?.length) {
        toast.error(error.response?.data?.message || "An unexpected error occurred");
    }

    const errorObject: FormikErrors<T> = {};

    Object.entries(errors ?? {}).forEach(([key, messages]) => {
        if (keys.includes(key as keyof T)) {
            errorObject[key as keyof T] = messages[0] as FormikErrors<T>[keyof T];
        }
    });

    return errorObject;
}
