const useGetFirstAndLastPages = (totalPages: number, currentPage: number) => {
    let startPage;
    let endPage;

    if (totalPages <= 5) {
        startPage = 1;
        endPage = totalPages;
    } else {
        if (currentPage <= Math.floor(5 / 2)) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + Math.floor(5 / 2) >= totalPages) {
            startPage = totalPages - 5 + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - Math.floor(5 / 2);
            endPage = currentPage + Math.floor(5 / 2);
        }
    }

    return { startPage, endPage };
}

export default useGetFirstAndLastPages;