import {FieldProps} from 'formik'
import React from 'react'
import Select from 'react-select'

type Props = {
  options: []
  placeholder: string | undefined
  defaultValue: null | {}
}

const AutoCompleteField: React.FC<Props & FieldProps> = ({options, field, form, placeholder, defaultValue = null}) => {
  //console.log(defaultValue)
  return (
    <Select
      options={options}
      name={field.name}
      defaultValue={defaultValue}
      onChange={(option: any) => form.setFieldValue(field.name, option.value)}
      onBlur={field.onBlur}
      placeholder={placeholder}
      //className="form-control form-control-lg form-control-solid border-0"
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#f5f8fa' : '#f5f8fa',
          background: "#f5f8fa",
          color: '#5e6278',
        }),
      }}
    />
    // <Autocomplete
    //     disablePortal
    //     options={options}
    //     defaultValue={defaultValue}
    //     onChange={(event: any, newValue: any) => form.setFieldValue(field.name, newValue.value)}
    //     renderInput={(params) => (
    //       <TextField
    //         {...params}
    //         name={'sessao_bf.sessao_id_bf'}
    //         className='form-control form-control-lg form-control-solid border-0'
    //         placeholder={placeholder}
    //       />
    //     )}
    //   />
  )
}

export {AutoCompleteField}
